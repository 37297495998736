/* eslint-disable */
export const environment = {
  production: false,
  useEmulators: false,
  webappBaseURL: 'https://testing.helplinesoftware.com',
  webchatBaseURL: 'https://testing-webchat.helplinesoftware.com',
  firebase: {
    apiKey: 'AIzaSyBxlUFtuC9VHge3cOP0ldWG4fZeTwE9Z80',
    authDomain: 'project-wolfpack.firebaseapp.com',
    databaseURL: 'https://project-wolfpack.firebaseio.com',
    projectId: 'project-wolfpack',
    storageBucket: 'project-wolfpack.appspot.com',
    messagingSenderId: '382411888553',
    appId: '1:382411888553:web:174b1b956aa87cc939d6b8',
  },
  fullCalendar: {
    licenseKey: '0415253183-fcs-1727959603',
  },
  surveyJS: {
    licenseKey: 'ZWYxZDNhM2EtZDZkMS00MTRkLTg1NDktZWRjNGQ1ZjFmZWY3OzE9MjAyNS0xMS0yMywyPTIwMjUtMTEtMjMsND0yMDI1LTExLTIz',
  },
};

export const projectNumber = 382411888553;
////////////////////////////////////////////////////////////////////////////////////////////
// Constants
////////////////////////////////////////////////////////////////////////////////////////////

const region = 'us-central1';
export const latestGitCommitHash = 'afa77ce';

////////////////////////////////////////////////////////////////////////////////////////////
// Env Specific Constants
////////////////////////////////////////////////////////////////////////////////////////////

// URL where functions are deployed
// The placeholder constant below is replaced with the actual hostname in a script.

export const host = `${region}-${environment.firebase.projectId}.cloudfunctions.net`;
export const baseURL = `https://${host}`;
