import type { ValidationResult } from 'joi';

import { SerializableObjectSchema } from '../../../../../objects/generic/serialization/serializable-object-schema';
import { PossibleAdminRole } from '../../../../../objects/user/admin-roles/types';
import { PossibleRole } from '../../../../../objects/user/roles/types';
import { CommunicationsRequestName } from '../../../communications-request-name';
import { CommunicationsRequest } from '../../generic/communications-request/communications-request';
import { CommunicationsRequestSchema } from '../../generic/communications-request/communications-request-schema';

import { CommunicationsRequestGetVoiceDeviceTokenConstructor } from './communications-request-get-voice-device-token-constructor';
import { CommunicationsRequestGetVoiceDeviceTokenSchema } from './communications-request-get-voice-device-token-schema';

export class CommunicationsRequestGetVoiceDeviceToken extends CommunicationsRequest {
  constructor(parameters: CommunicationsRequestGetVoiceDeviceTokenConstructor) {
    super({
      ...parameters,
      [CommunicationsRequestSchema.type]: CommunicationsRequestName.getVoiceDeviceToken,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  protected static _deserialize(validationResult: ValidationResult): CommunicationsRequestGetVoiceDeviceToken {
    return new CommunicationsRequestGetVoiceDeviceToken(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationsRequestGetVoiceDeviceToken.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationsRequestGetVoiceDeviceTokenSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////

  public getRequiredRoles(): PossibleRole[] {
    return [];
  }

  public getRequiredAdminRoles(): PossibleAdminRole[] | undefined {
    return undefined;
  }
}
